// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Profile from './components/Profile'; // Import Profile component
import Login from './components/Login';
import Trainer from './components/Trainer';
import About from './components/About';
import WordsDisplay from './components/WordsDisplay';
import NavigationBar from './components/NavigationBar';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import { removeToken } from './utils/auth';





function App() {
  // User Functionality
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [username, setUsername] = useState(''); // State to track username

// User Functionality
  // Function to handle logout
  const handleLogout = () => {
    removeToken()
    setIsLoggedIn(false);
    setUsername('');
    window.location.href = "/";
    toast.success('You have successfully logged out.');  // Toast notification for logout success
  };

  useEffect(() => {
    // Check if token is available
    const token = localStorage.getItem('access_token');
    
    if (!token) {
      // If no token, user is not logged in
      setIsLoggedIn(false);
      return;
    }
  
    try {
      // Try decoding the token to get the user ID
      const decoded = jwtDecode(token);
      const userId = decoded.user_id;
  
      // Proceed to fetch user info if token is available
      axios.get(`/api/users/${userId}/`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setUsername(response.data.username);  // Set username
        setIsLoggedIn(true);  // Mark user as logged in
      })
      .catch((error) => {
        console.error('Error fetching user information:', error);
        
        if (error.response && error.response.status === 401) {
          // If token is invalid or expired, clear it from localStorage
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          setIsLoggedIn(false);  // Mark user as logged out
          setUsername('');  // Clear username
        }
      });
    } catch (error) {
      // If decoding the token fails, it is likely invalid or corrupted
      console.error('Error decoding token:', error);
      localStorage.removeItem('access_token');  // Remove invalid token
      localStorage.removeItem('refresh_token');
      setIsLoggedIn(false);  // Mark user as logged out
      setUsername('');  // Clear username
    }
  }, []);  // Only run this effect on component mount
  





  return (
    <Router>
      <ToastContainer position="top-right" autoClose={2000} />
      <NavigationBar isLoggedIn={isLoggedIn} username={username} handleLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<Trainer isLoggedIn={isLoggedIn} />} /> {/* Pass isLoggedIn to Trainer */}
        <Route path="/About" element={<About />} />
        <Route path="/words-display" element={<WordsDisplay />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={isLoggedIn ? <Profile /> : <Login />} /> {/* Add Profile route */}
      </Routes>
    </Router>
  );
}

export default App;
