// src/utils/auth.js
import axios from 'axios';

export const getToken = () => {
  return localStorage.getItem('access_token');
};

// Save the JWT token to localStorage
export const setToken = (accessToken, refreshToken) => {
  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);
};

// Remove the JWT tokens from localStorage (for logging out)
export const removeToken = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

export const axiosInstance = axios.create({
  baseURL: 'http://localhost:8000',
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
