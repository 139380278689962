// Trainer.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import debounce from 'lodash/debounce'; // debounce text-selection effect
import { toast } from 'react-toastify'; // Import Toastify
import { handleTranslateMissingWords } from '../utils/apiUtils'; // Import the translation function


function Trainer({ isLoggedIn }) {
  const [userInput, setUserInput] = useState(''); // State for language input for prompt
  const [words, setWords] = useState([]);  // State to hold words from the database
  const [difficulty, setDifficulty] = useState('Beginner'); // Choose difficulty level for prompt
  const [topicFocus, setTopicFocus] = useState('Daily_Life'); // Choose topic for prompt
  const [lengthSentences, setLengthSentences] = useState('Random'); // Choose length of sentences
  const [includeWords, setIncludeWords] = useState(false);  // State to manage checkbox wether to include word from Database
  const [includeTransliteration, setIncludeTransliteration] = useState(false);  // State to manage checkbox wether to include Transliteration
  const [isSubmitting, setIsSubmitting] = useState(false);  // State to track submission status
  const [selectedText, setSelectedText] = useState(''); // State for selected text
  const [selectedLanguage, setSelectedLanguage] = useState(''); // State to store selected language
  const [lastGptResponse, setLastGptResponse] = useState(''); // New state for storing the last GPT response
// save the last 10 insight responses, and navigate through them
  const [insightResponses, setInsightResponses] = useState([]); // Array to store recent insight responses
  const [currentInsightIndex, setCurrentInsightIndex] = useState(-1); // Index to track the current response
// save the last 10 sentence request responses, and navigate through them
  const [sentenceResponses, setSentenceResponses] = useState([]); // Array to store recent sentence responses
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(-1); // Index to track the current response

// translation functionality
  const [isTranslating, setIsTranslating] = useState(false); // Track translation status
  const [translationMessage, setTranslationMessage] = useState(''); // To display messages

  const [showMoreOptions, setShowMoreOptions] = useState(false); // State to control the visibility of additional options



  // Set the base URL from the environment variable
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;


  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions); // Toggle the state
  };


  // Function to get the CSRF token for secure POST requests
  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };


// Function to fetch words from the database based on selected language
const fetchWords = async (language) => {

  if (!isLoggedIn) {
    console.warn("User is not logged in. Aborting fetchWords.");
    return; // Exit the function if not logged in
  }

  try {
    const token = localStorage.getItem('access_token'); // Get the JWT token (if the user is logged in)
    
    const headers = {
      'Content-Type': 'application/json',
    };

    // If the user is logged in, include the Authorization header
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const res = await axios.get('/api/get-words/', {
      params: { language: language },  // Send language as a query parameter
      headers: headers // Include headers with token if available
    });

    setWords(res.data.words); // Update the state with the fetched words

  } catch (error) {
    console.error("There was an error fetching the words!", error);
  }
};



useEffect(() => {
  // Define the handler to get the selected text
  const handleSelection = () => {
    const selected = window.getSelection().toString().trim();
    setSelectedText(selected);
  };

  // Use debounce to delay the execution of the function
  const debounceHandleSelection = debounce(handleSelection, 400); // Add debounce here

  // Attach the debounced event listener to the mouseup event
  document.addEventListener('mouseup', debounceHandleSelection);

  // Cleanup the event listener when the component unmounts
  return () => {
    document.removeEventListener('mouseup', debounceHandleSelection);
  };
}, []);


 // Function to save the selected word to the database
const saveWordToDatabase = () => {
  if (!selectedText) {
    toast.error('Please select a word to save.');
    return; // Exit the function if no text is selected
  }

  if (!selectedLanguage) {
    toast.error('Please select a language before saving the word.');
    return; // Exit the function if no language is selected
  }

  const csrftoken = getCookie('csrftoken');
  const token = localStorage.getItem('access_token'); // Get the JWT token

  const headers = {
    'X-CSRFToken': csrftoken,
    'Content-Type': 'application/json',
  };

  // Include the Authorization header only if the user is logged in
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  axios.post('/api/save-word/', {
    language: selectedLanguage,
    word: selectedText,
  }, {
    headers: headers
  })
  .then((response) => {
    toast.success('Word saved successfully!');
    fetchWords(selectedLanguage);  // Fetch the updated word list after saving
  })
  .catch((error) => {
    console.error('There was an error saving the word!', error);
    toast.error('Failed to save the word. Please try again.');
  });
};
  // Function to fetch word insight from the server
  const fetchWordInsight = async () => {
    if (!selectedText) return; // Only proceed if there's selected text

    setIsSubmitting(true);
    try {
      const res = await axios.post('/api/word-insight/', {
        word: selectedText,
        language: selectedLanguage // Include the stored language in the request
      });

      const newResponse = res.data.answer;

        // Update the responses array
      setInsightResponses(prevResponses => {
        const updatedResponses = [newResponse, ...prevResponses].slice(0, 10); // Keep only the latest 10 responses
        setCurrentInsightIndex(0); // Reset to the latest response
        return updatedResponses;
      });
    } catch (error) {
      console.error("There was an error fetching the word insight!", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);  // Disable the button and apply greyscale
    setSelectedLanguage(userInput);  // Store the selected language
    fetchWords(userInput);  // Fetch the updated word list after saving

    try {
      const res = await axios.post('/api/ask/', {
        user_input: userInput,
        difficulty: difficulty,
        topic_focus: topicFocus,
        length_sentences: lengthSentences,
        include_words: includeWords,
        include_transliteration: includeTransliteration,
        last_response: lastGptResponse // Pass the last GPT response to the server
      });

      const newResponse = res.data.answer;

      // Update the responses array
      setSentenceResponses(prevResponses => {
        const updatedResponses = [newResponse, ...prevResponses].slice(0, 10); // Keep only the latest 10 responses
        setCurrentSentenceIndex(0); // Reset to the latest response
        return updatedResponses;
      });

      setLastGptResponse(res.data.answer);
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setIsSubmitting(false);  // Re-enable the button and remove greyscale
    }
  };


  // Helper function to truncate text to a specified length
  const truncateText = (text, length) => {
    if (text && text.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
  };


// Function to handle navigation with arrows
const handleInsightNavigate = (direction) => {
  if (direction === 'back' && currentInsightIndex < insightResponses.length - 1) {
      setCurrentInsightIndex(prevIndex => prevIndex + 1);
  } else if (direction === 'forward' && currentInsightIndex > 0) {
      setCurrentInsightIndex(prevIndex => prevIndex - 1);
  }
};

// Function to handle navigation for sentence responses
const handleSentenceNavigate = (direction) => {
  if (direction === 'back' && currentSentenceIndex < sentenceResponses.length - 1) {
      setCurrentSentenceIndex(prevIndex => prevIndex + 1);
  } else if (direction === 'forward' && currentSentenceIndex > 0) {
      setCurrentSentenceIndex(prevIndex => prevIndex - 1);
  }
};



const downloadInsightsHTML = () => {
  const styleContent = `
      body { font-family: Arial, sans-serif; line-height: 1.6; }
      .insight-output { padding: 20px; background-color: #f9f9f9; border-radius: 8px; border: 1px solid #ddd; }
      .insight-output h2 { font-size: 24px; }
      .insight-output p { margin-bottom: 10px; }
      .insight-box { margin-bottom: 20px; padding: 15px; background-color: #fff; border: 1px solid #ddd; border-radius: 8px; }
      .insight-box h3 { margin-top: 0; }
  `;

  const insightsHtml = insightResponses.map((insight, index) => `
      <div class="insight-box">
          <h3>Insight ${index + 1}</h3>
          <p>${insight}</p>
      </div>
  `).join('');

  const htmlContent = `
      <html>
      <head>
          <meta charset="UTF-8">
          <style>${styleContent}</style>
      </head>
      <body>
          <div class="insight-output">
              <h2>Word Insights</h2>
              ${insightsHtml}
          </div>
      </body>
      </html>
  `;
  
  const blob = new Blob([htmlContent], { type: 'text/html' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'word_insights.html';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


const handleSubmitParagraphs = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);  // Disable the button and apply greyscale
  setSelectedLanguage(userInput);  // Store the selected language
  fetchWords(userInput);

  try {
    const res = await axios.post('/api/ask-gpt-paragraphs/', {
      user_input: userInput,
      difficulty: difficulty,
      topic_focus: topicFocus,
      length_sentences: lengthSentences,
      include_words: includeWords,
      include_transliteration: includeTransliteration,
      last_response: lastGptResponse // Pass the last GPT response to the server
    });

    const newResponse = res.data.answer;

    // Update the responses array
    setSentenceResponses(prevResponses => {
      const updatedResponses = [newResponse, ...prevResponses].slice(0, 10); // Keep only the latest 10 responses
      setCurrentSentenceIndex(0); // Reset to the latest response
      return updatedResponses;
    });

    setLastGptResponse(res.data.answer);
  } catch (error) {
    console.error("There was an error!", error);
  } finally {
    setIsSubmitting(false);  // Re-enable the button and remove greyscale
  }
};

const handleSubmitVocabulary = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);  // Disable the button and apply greyscale
  setSelectedLanguage(userInput);  // Store the selected language
  fetchWords(userInput);

  try {
    const res = await axios.post('/api/ask-gpt-vocabulary/', {
      user_input: userInput,
      difficulty: difficulty,
      topic_focus: topicFocus,
      include_words: includeWords,
      include_transliteration: includeTransliteration,
      last_response: lastGptResponse // Pass the last GPT response to the server
    });

    const newResponse = res.data.answer;

    // Update the responses array
    setSentenceResponses(prevResponses => {
      const updatedResponses = [newResponse, ...prevResponses].slice(0, 10); // Keep only the latest 10 responses
      setCurrentSentenceIndex(0); // Reset to the latest response
      return updatedResponses;
    });

    setLastGptResponse(res.data.answer);
  } catch (error) {
    console.error("There was an error!", error);
  } finally {
    setIsSubmitting(false);  // Re-enable the button and remove greyscale
  }
};



const handleTranslateClick = () => {
  handleTranslateMissingWords(selectedLanguage, setWords, setIsTranslating, setTranslationMessage);
  fetchWords(selectedLanguage);  // Fetch the updated word list after saving
};

  // Trigger a toast notification whenever translationMessage changes
  useEffect(() => {
    if (translationMessage) {
      toast.info(translationMessage, { autoClose: 3000 });
    }
  }, [translationMessage]);












  

  return (
    <div className = "container">
      <div className="trainer-container">
        <div className="form-container">
          {/* Form content */}
          <form onSubmit={handleSubmit}>
            <textarea
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              rows="2"
              cols="10"
              placeholder="Type the Language you want to practice here..."
            />

            <br />

            <div className="form-row">
              <div className="form-group">
                <label>Difficulty</label>
                <select value={difficulty} onChange={(e) => setDifficulty(e.target.value)}>
                  <option value="Absolute Beginner">Absolute Beginner</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                  <option value="Fluent">Fluent</option>
                </select>
              </div>

              <div className="form-group">
                <label>Topic</label>
                <select value={topicFocus} onChange={(e) => setTopicFocus(e.target.value)}>
                  <option value="Random">Random</option>
                  <option value="Daily_Life">Daily Life</option>
                  <option value="Proverbs">Proverbs</option>
                  <option value="Food & Dining">Food & Dining</option>
                  <option value="Family & Friends">Family & Friends</option>
                  <option value="Sports">Sports</option>
                  <option value="Technology">Technology</option>
                  <option value="Medical">Medical</option>
                  <option value="Science">Science</option>
                  <option value="Nature">Nature</option>
                  <option value="Casual">Casual</option>
                  <option value="Formal">Formal</option>
                  <option value="Conversational">Conversational</option>
                  <option value="Academic">Academic</option>
                </select>
              </div>
            </div>


            <div className="more-options-container">
              <label className="more-options-label">More Options</label>
              <label className="switch">
                <input type="checkbox" checked={showMoreOptions} onChange={toggleMoreOptions} />
                <span className="slider round"></span>
              </label>
            </div>


            {showMoreOptions && (
              <div className="form-row">
                <div className="form-group">
                  <label>Length</label>
                  <select value={lengthSentences} onChange={(e) => setLengthSentences(e.target.value)}>
                    <option value="random">Random</option>
                    <option value="short">Short</option>
                    <option value="medium">Medium</option>
                    <option value="long">Long</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Include Saved Words</label>
                  <input
                    type="checkbox"
                    checked={includeWords}
                    onChange={(e) => setIncludeWords(e.target.checked)}
                  />
                </div>
                <div className="form-group">
                  <label>Pronunciation</label>
                  <input
                    type="checkbox"
                    checked={includeTransliteration}
                    onChange={(e) => setIncludeTransliteration(e.target.checked)}
                  />
                </div>
              </div>
            )}

            <button
              type="button"
              onClick={handleSubmit} 
              disabled={isSubmitting}
              className={`button-spacing ${isSubmitting ? 'disabled' : ''}`}>
              Sentences
            </button>

            <button 
              type="button" 
              onClick={handleSubmitParagraphs} 
              disabled={isSubmitting} 
              className={`button-spacing ${isSubmitting ? 'disabled' : ''}`}>
              Paragraphs
            </button>

            <button 
              type="button" 
              onClick={handleSubmitVocabulary} 
              disabled={isSubmitting} 
              className={`button-spacing ${isSubmitting ? 'disabled' : ''}`}>
              Vocabulary
            </button>
          </form>
        </div>
      </div>

      <div>
        <div className="response-output">
          {/* Section to display default message or Sentence Responses with navigation */}
          {sentenceResponses.length > 0 ? (
            <div>
              <h2>Practice</h2>
              <div
                id="response-output"
                dangerouslySetInnerHTML={{ __html: sentenceResponses[currentSentenceIndex].replace(/\n/g, '<br />') }}
              />

              {/* Navigation Arrows */}
              <div className="navigation-arrows">
                <button
                  onClick={() => handleSentenceNavigate('back')}
                  disabled={currentSentenceIndex >= sentenceResponses.length - 1 || isSubmitting}
                >
                  ←
                </button>
                <button
                  onClick={() => handleSentenceNavigate('forward')}
                  disabled={currentSentenceIndex <= 0 || isSubmitting}
                >
                  →
                </button>
              </div>
            </div>
          ) : (
            <div>
              <h2>Practice Languages with LingoTrainer</h2>
              <p>
                LingoTrainer is an innovative language learning tool designed to help you practice and master new languages. 
                Whether you're a beginner or an advanced learner, LingoTrainer offers personalized practice sentences, word insights, and more to enhance your language learning experience.
              </p>
              <h2>Features</h2>
              <ul>
                <li>Generate practice sentences based on your selected difficulty and context.</li>
                <li>Get detailed insights on specific words, including etymology and usage.</li>
                <li>Save and track the words you’ve learned in your personal database.</li>
                <li>Download stored words and insights for offline study.</li>
              </ul>
              <p>
                Our mission is to make language learning more accessible and engaging by leveraging the power of AI.
              </p>
            </div>
          )}
        </div>
      </div>




      {selectedText && (
        <div className="selected-text-buttons">
          <button
            onClick={fetchWordInsight}
            className={isSubmitting ? 'insight-button disabled' : 'insight-button'}
            disabled={isSubmitting}
          >
            Get Insight on "{truncateText(selectedText, 15)}"
          </button>
          
          {isLoggedIn && (
            <button
              onClick={saveWordToDatabase}
              className={isSubmitting ? 'save-word-button disabled' : 'save-word-button'}
              disabled={isSubmitting}
            >
              Save Word "{truncateText(selectedText, 15)}"
            </button>
          )}
        </div>
      )}


      {/* Section to display Word Insight with navigation */}
      {insightResponses.length > 0 && (
        <div className="insight-output">
          <h2>Word Insight</h2>
          <div dangerouslySetInnerHTML={{ __html: insightResponses[currentInsightIndex].replace(/\n/g, '<br />') }} />

          {/* Navigation Arrows */}
          <div className="navigation-arrows">
            <button
              onClick={() => handleInsightNavigate('back')}
              disabled={currentInsightIndex >= insightResponses.length - 1 || isSubmitting}
            >
              ← 
            </button>
            <button
              onClick={() => handleInsightNavigate('forward')}
              disabled={currentInsightIndex <= 0 || isSubmitting}
            >
              →
            </button>
          </div>

          {/* Add a button to download the insights as PDF */}
          <button onClick={downloadInsightsHTML} className="download-HTML-button">
            Download Insights
          </button>
        </div>
      )}
      {isLoggedIn && (
        <div className="word-list-container">
          <h3>Saved Words</h3>
          <div>
            {words.length > 0 && (
              <>
                <span className="language-label">Language:</span> <span>{selectedLanguage}</span> {/* Use a span for inline display */}
              </>
            )}
          </div>

          <ul>
            {words.length > 0 ? (
              words.map((wordObj, index) => (
                <li key={index}>
                  <strong>{wordObj.word}</strong>
                  {wordObj.translation ? ` - ${wordObj.translation}` : ""}
                </li>
              ))
            ) : (
              <p>No words saved yet</p>  // Display a message if there are no words
            )}
          </ul>

          {/* Conditionally render the buttons only if there are words */}
          {words.length > 0 && (
            <>
              {/* Check if any word is missing a translation */}
              {words.some(wordObj => !wordObj.translation) && (
                <button
                  onClick={handleTranslateClick}
                  disabled={isTranslating}
                  className={`button-spacing ${isTranslating ? 'disabled' : ''}`}
                >
                Translate
                </button>
              )}
            </>
          )}
        </div>
      )}



    </div>
  );
}

export default Trainer;
