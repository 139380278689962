// src/components/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { setToken } from '../utils/auth';



function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Create the user and profile
      const response = await axios.post('/api/register/', {
        username,
        password,
      });
      toast.success(response.data.message);

      // Automatically log in the user after registration
      const loginResponse = await axios.post('/api/login/', {
        username,
        password,
      });

  
      // Save the token in localStorage
      const { access, refresh } = loginResponse.data;
      setToken(access, refresh);  // Store token in state
      
      // Fetch and set the username immediately after login
      const decoded = jwtDecode(access);
      const userId = decoded.user_id;

      // Save tokens and user_id to localStorage
      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);
      localStorage.setItem('user_id', userId);

      

      // Redirect to the main page
      window.location.href = "/";
    } catch (err) {
      toast.error('Registration failed. Username may already be taken.');
    }
  };

  return (
    <div className="container">
      <h2>Register</h2>
      <form onSubmit={handleRegister}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default Register;
