// apiUtils.js
import axios from 'axios';

// Function to handle translation for missing words
export const handleTranslateMissingWords = async (language, setWords, setIsTranslating, setTranslationMessage) => {
  setIsTranslating(true); // Set translation in progress
  setTranslationMessage(''); // Clear any previous messages

  try {
    const token = localStorage.getItem('access_token'); // Retrieve JWT token if logged in
    const headers = { 'Content-Type': 'application/json' };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const res = await axios.post('/api/translate-missing-words/', { language }, { headers });

    // Handle the response
    if (res.data.message) {
      setTranslationMessage(res.data.message);
    } else if (res.data.error) {
      setTranslationMessage(res.data.error);
    }
    
    // Optionally, refetch words after translation to update the list
    const wordsRes = await axios.get('/api/get-words/', { params: { language }, headers });
    setWords(wordsRes.data.words);
  } catch (error) {
    console.error("Error translating words:", error);
    setTranslationMessage('An error occurred during translation.');
  } finally {
    setIsTranslating(false); // Mark translation as complete
  }
};
