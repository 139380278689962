// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { setToken } from '../utils/auth';

function Login() {
  const [username, setUsername] = useState('');  // State for the input field
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login/', {
        username,
        password,
      });
      const { access, refresh } = response.data;
      setToken(access, refresh);  // Store token in state

      // Fetch and set the username immediately after login
      const decoded = jwtDecode(access);
      const userId = decoded.user_id;

      // Save tokens and user_id to localStorage
      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);
      localStorage.setItem('user_id', userId);


      const userResponse = await axios.get(`/api/users/${userId}/`, {
        headers: { Authorization: `Bearer ${access}` }
      });
      
      const fetchedUsername = userResponse.data.username;  // Renamed to fetchedUsername
      setUsername(fetchedUsername);  // Set fetched username to state

      toast.success(`Welcome, ${fetchedUsername}! You are now logged in.`);

      window.location.href = "/";

    } catch (err) {
      console.log(err)
      toast.error('Login failed. Check your credentials.');
    }
  };

  return (
    <div className="container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
          autoComplete="username"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          autoComplete="current-password"
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;
