// NavigationBar.js
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../myassets/logo.png';
import '../App.css';

function NavigationBar({ isLoggedIn, username, handleLogout }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const onLogout = () => {
    handleLogout();
    navigate('/');
  };

  useEffect(() => {
    if (!menuOpen) return;
  
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
  
    document.addEventListener('mouseup', handleClickOutside);
    return () => document.removeEventListener('mouseup', handleClickOutside);
  }, [menuOpen]);

  return (
    <header className="navbar">
      <div className="navbar-left">
        <button className="nav-button" onClick={() => setMenuOpen(!menuOpen)}>
          ☰
        </button>
        {menuOpen && (
          <div className="dropdown-menu" ref={menuRef}>
            <Link to="/" onClick={() => setMenuOpen(false)}>Trainer</Link>
            <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
            {isLoggedIn && <Link to="/words-display" onClick={() => setMenuOpen(false)}>Stored Words</Link>}
            {isLoggedIn && <Link to="/profile" onClick={() => setMenuOpen(false)}>Profile</Link>}
          </div>
        )}
        <Link to="/">
          <img src={logo} alt="LingoTrainer Logo" className="title-logo" />
        </Link>
        <h1 className="navbar-title">LingoTrainer</h1>
      </div>
      <div className="navbar-right">
        {!isLoggedIn ? (
          <>
            <Link to="/login"><button>Login</button></Link>
            <Link to="/register"><button>Register</button></Link>
          </>
        ) : (
          <>
            <span>Welcome, {username}!</span>
            <button onClick={onLogout}>Logout</button>
          </>
        )}
      </div>
    </header>
  );
}

export default NavigationBar;
