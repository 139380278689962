// WordsDisplay.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import { toast } from 'react-toastify';
import { handleTranslateMissingWords } from '../utils/apiUtils'; // Import the translation function

function WordsDisplay() {
  const [words, setWords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [translationMessage, setTranslationMessage] = useState('');

  // Show translation message as toast if it changes
  useEffect(() => {
    if (translationMessage) {
      toast.info(translationMessage, { autoClose: 3000 });
    }
  }, [translationMessage]);

  // Initial fetch of all words
  const fetchAllWords = async (languageToKeep = '') => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { 'Content-Type': 'application/json' };
      if (token) headers['Authorization'] = `Bearer ${token}`;

      const res = await axios.get('/api/get-all-words/', { headers });
      setWords(res.data.words);
      setLoading(false);

      // Set selected language folder
      const languages = [...new Set(res.data.words.map(word => word.language))].sort();
      if (languages.length > 0) {
        setSelectedLanguage(languageToKeep || languages[0]);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error('Session expired. Please log in again.');
        localStorage.removeItem('access_token');
      } else {
        toast.error('Failed to fetch words from the database.');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllWords();
  }, []);

  const handleTranslateClick = async () => {
    await handleTranslateMissingWords(selectedLanguage, setWords, setIsTranslating, setTranslationMessage);
    fetchAllWords(selectedLanguage); // Fetch words again to update the list
  };

  const filteredWords = words.filter(word => word.language === selectedLanguage);

  if (loading) {
    return <div>Loading...</div>;
  }

  const languages = [...new Set(words.map(word => word.language))].sort();

  const toggleEditMode = () => setEditMode(!editMode);

  const handleDeleteLanguage = () => {
    toast(
      <div>
        Are you sure you want to delete all words for "{selectedLanguage}"?
        <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          <button onClick={() => confirmDeletion(true)}>Yes</button>
          <button onClick={() => confirmDeletion(false)}>No</button>
        </div>
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
      }
    );
  };

  const confirmDeletion = async (confirmed) => {
    toast.dismiss();

    if (confirmed) {
      try {
        const token = localStorage.getItem('access_token');
        const headers = { 'Content-Type': 'application/json' };
        if (token) headers['Authorization'] = `Bearer ${token}`;

        await axios.delete('/api/delete-language-words/', {
          data: { language: selectedLanguage },
          headers,
        });

        fetchAllWords(); // Fetch updated words list after deletion
        toast.success(`${selectedLanguage} deleted successfully.`);
      } catch (err) {
        toast.error('Failed to delete the language.');
      }
    }
  };

  const downloadWordListCSV = () => {
    const csvContent = [
      ["Word", "Translation", "Etymology"], // Headers
      ...filteredWords.map(word => [
        word.word,
        word.translation || 'No translation available',
        word.etymology || 'No etymology available',
      ]),
    ]
      .map(row => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", `words_${selectedLanguage}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="words-display-container">
      <div className="title-header">
        <h1>Stored Words</h1>
      </div>
      <div className="language-folders">
        {languages.map(language => (
          <button
            key={language}
            onClick={() => setSelectedLanguage(language)}
            className={`folder-button ${language === selectedLanguage ? 'active' : ''}`}
          >
            {language}
          </button>
        ))}
        <button
          onClick={toggleEditMode}
          className={`edit-button ${editMode ? 'edit-mode' : ''}`}
        >
          Actions
        </button>
      </div>

      {editMode && (
        <div className="action-buttons">
          <button
            onClick={handleTranslateClick}
            disabled={isTranslating}
            className={`button-spacing ${isTranslating ? 'disabled' : ''}`}
          >
            Translate
          </button>
          <button onClick={downloadWordListCSV} className="download-button">Download</button>
          <button className="delete-button" onClick={handleDeleteLanguage}>Delete</button>
        </div>
      )}

      <table className="words-table">
        <thead>
          <tr>
            <th>Word</th>
            <th>Translation</th>
            <th>Etymology</th>
          </tr>
        </thead>
        <tbody>
          {filteredWords.length > 0 ? (
            filteredWords.map((wordObj, index) => (
              <tr key={index}>
                <td><strong>{wordObj.word}</strong></td>
                <td>{wordObj.translation || 'No translation available'}</td>
                <td>{wordObj.etymology || 'No etymology available'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No words saved yet.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default WordsDisplay;
