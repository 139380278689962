// src/components/Profile.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function Profile() {
  const [profile, setProfile] = useState({
    username: '',
    favoriteLanguages: '',
    nativeLanguages: '',
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`/api/users/${localStorage.getItem('user_id')}/`, { headers });
        const data = response.data;

        // Populate profile with data from backend
        setProfile({
          username: data.username || '',
          favoriteLanguages: data.favorite_languages || '',
          nativeLanguages: data.native_languages || '',
        });
      } catch (error) {
        toast.error("Failed to load profile information.");
      }
    };
    fetchProfile();
  }, []);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
      
      const updatedProfile = {
        favorite_languages: profile.favoriteLanguages,
        native_languages: profile.nativeLanguages,
      };
      
      
      await axios.put('/api/user/update/', updatedProfile, { headers });
      setIsEditing(false);
      toast.success("Profile updated successfully.");
    } catch (error) {
      toast.error("Failed to update profile.");
    }
  };

  return (
    <div className="container">
      <div className="profile-container">
        <h2>My Profile</h2>
        <form>
          <div>
            <label>Username:</label>
            <input type="text" value={profile.username} disabled />
          </div>
          <div>
            <label>Favorite Languages:</label>
            <input
              type="text"
              name="favoriteLanguages"
              value={profile.favoriteLanguages}
              onChange={handleInputChange}
              placeholder="e.g., French, Spanish"
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Native Languages:</label>
            <input
              type="text"
              name="nativeLanguages"
              value={profile.nativeLanguages}
              onChange={handleInputChange}
              placeholder="e.g., English"
              disabled={!isEditing}
            />
          </div>
          {isEditing ? (
            <button type="button" onClick={handleSave}>Save</button>
          ) : (
            <button type="button" onClick={handleEditToggle}>Edit Profile</button>
          )}
        </form>
      </div>
    </div>
  );
}

export default Profile;
